import React, { useEffect } from "react";
import "../../styles/global.css";
import { Helmet } from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import { MathJax, MathJaxContext } from "better-react-mathjax";

// import { FacebookProvider, Comments, Like, CustomChat } from 'react-facebook';
import { Disqus } from "gatsby-plugin-disqus";

const Layout = ({ children }) => {
  const isBrowser = typeof window !== "undefined";

  return (
    <MathJaxContext>
      <Header />
      {/* <div className="max-w-4xl m-auto text-center bg-gray-400 border-8 text-white text-3xl border-red-500 border-dotted my-5 p-9">
        <a href="https://deprecated.seismicconsolidation.com/">
          <p>
            Our Site is under Construction if you want to visit old site. Click
            Here
          </p>
        </a>
      </div> */}
      <main className="m-auto max-w-5xl">{children}</main>

      {/* {isBrowser && (
        <div className="max-w-5xl m-auto mt-12  border-t-2 border-black">
          <FacebookProvider appId="591737088590656">
            <Like className='float-right' href={window.location.href} colorScheme="dark" showFaces share />
            <Comments href={window.location.href} />
          </FacebookProvider>
        </div>
      )} */}

      <div className="max-w-5xl m-auto mt-12  border-t-2 border-black">
        <Disqus />
      </div>
      <Footer />
    </MathJaxContext>
  );
};

export default Layout;
