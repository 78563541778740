import React from 'react'
import { Link } from 'gatsby'


const Footer = () => {
    return (
        <div className="bg-yellow-500 text-center text-white p-5 mt-5 text-xl">
            <p className='text-3xl'>All Rights Reserved</p>
            <div className='flex flex-wrap justify-around m-auto max-w-4xl mt-4'>
                <Link to='/'>Home</Link>
                <Link to='/'>About Us</Link>
                <Link to='/'>Contact Us</Link>
                <Link to='/'>Privacy Policy</Link>
                <Link to='/'>Terms and Conditions</Link>
                <Link to='/'>Disclaimer</Link>
            </div>
        </div>
    )
}

export default Footer
