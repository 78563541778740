import React, { useState } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const Header = () => {
  const [menuOpen, SetMenuOpen] = useState(false);
  return (
    <div>
      {/* <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2299637749156309"
          crossorigin="anonymous"
        ></script>
      </Helmet> */}
      <div className="bg-yellow-400 flex justify-around items-center h-24 text-white p-12 ">
        <p className="text-3xl">
          <Link to="/">Seismic Consolidation</Link>
        </p>
        {/* <div className="cursor-pointer" onClick={() => SetMenuOpen(!menuOpen)}>
          <div className="w-8 h-1 m-2 bg-white"></div>
          <div className="w-8 h-1 m-2 bg-white"></div>
          <div className="w-8 h-1 m-2 bg-white"></div>
        </div> */}
      </div>
      {/* {menuOpen && 
                <div className="fixed right-0 w-96 z-10 md:mr-10 bg-yellow-300 text-white text-center">
                    <ul>
                        <li>Basic Civil Engineering</li>
                        <li>Structure Engineering</li>
                        <li>Geotechnicl Engineering</li>
                        <li>Transportation Engineering</li>
                        <li>Hydraulics & Irrugation Engineering</li>
                        <li>Enviornmental Engineering</li>
                        <li>Construction Management</li>
                        <li>Intermediate</li>
                        <li>Competitive Exam</li>
                        <li>Softwares</li>
                        <li>Past Papers</li>
                        <li>Civil Engineering Objective</li>
                        <li>Turnitin Plagarism Report</li>
                        <li>Membership</li>
                    </ul>
                </div>
                } */}
    </div>
  );
};

export default Header;
